/**
 * A composable for managing the product wishlist of a customer.
 */
export function useProductWishlist() {
    const productWishlistStore = useProductWishlistStore()
    const { t } = useI18n()

    const { notifyError } = useNotifications()

    const customerProductListItemsService = useCustomerProductListItemsApiService({
        customerProductListId: () => productWishlistStore.productWishlistId,
    })

    /**
     * Adds a product or product variation to the wishlist.
     * If no wishlist exists, it is created first.
     *
     * @param product
     * @param variation
     * @returns `true` if the product was added to the wishlist, `false` otherwise.
     */
    async function addToWishlist(product: InstanceType<typeof ProductModel>, variation?: InstanceType<typeof ProductVariationModel> | null): Promise<boolean> {
        if (!product.id) {
            notifyError()
            return false
        }

        if (!productWishlistStore.productWishlistId) {
            const wishlist = await productWishlistStore.createProductWishlist()
            if (!wishlist) {
                productWishlistStore.refreshProductWishlistId()
                notifyError(t('_core_theme.errors.cant_add_product_to_fav'))
                return false
            }
        }

        let success = false

        try {
            const response = await customerProductListItemsService.post({
                note: null,
                product_id: product.id,
                product_variation_id: variation?.id,
            })

            // update the product model
            if (variation && variation.id) {
                const listItem = response.getItem()
                if (listItem && product.customerProductVariationsWishlistItems) {
                    product.customerProductVariationsWishlistItems = {
                        ...product.customerProductVariationsWishlistItems,
                        [variation.id]: listItem,
                    }
                }
            } else {
                product.customerProductWishListItemId = response.getItem()?.id
            }

            success = true
        } catch (e) {
            let errorMessage: string | null = null
            if (e instanceof ApiResponseError) {
                errorMessage = e.getErrorMessage()
                productWishlistStore.refreshProductWishlistId()

                if (e.isStatus(404)) {
                    errorMessage = t('_core_theme.errors.product_list_not_found')
                }
            }
            notifyError(errorMessage || t('_core_theme.errors.cant_add_product_to_fav'))
        }

        return success
    }

    /**
     * Removes a product or product variation from the wishlist.
     * To remove a variation, both the product and the variation must be provided.
     *
     * @param product
     * @param variation
     * @returns `true` if the product was removed from the wishlist, `false` otherwise.
     */
    async function removeFromWishlist(product: InstanceType<typeof ProductModel>, variation?: InstanceType<typeof ProductVariationModel> | null): Promise<boolean> {
        if (!product.id || !productWishlistStore.productWishlistId) {
            notifyError()
            return false
        }

        const listItemIdToRemove = product.getWishlistItemId(variation)

        if (!listItemIdToRemove) {
            if (import.meta.dev) {
                errorLog('[useProductWishlist:removeFromWishlist]: No list item ID found.')
            }
            notifyError()
            return false
        }

        let success = false

        try {
            await customerProductListItemsService
                .forId(listItemIdToRemove)
                .delete()

            // update the product model
            if (variation && variation.id) {
                if (product.customerProductVariationsWishlistItems) {
                    delete product.customerProductVariationsWishlistItems[variation.id]
                }
            } else {
                product.customerProductWishListItemId = null
            }

            success = true
        } catch (e) {
            let errorMessage: string | null = null
            if (e instanceof ApiResponseError) {
                errorMessage = e.getErrorMessage()
            }
            notifyError(errorMessage || t('_core_theme.errors.cant_remove_product_from_fav'))
        }

        return success
    }


    return {
        addToWishlist: addToWishlist,
        removeFromWishlist: removeFromWishlist,
        productWishlistId: productWishlistStore.productWishlistId,
    }
}
