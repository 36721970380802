<script lang="tsx">
import type { SlotsType } from 'vue'
import type { ComponentOverrideOptions, SizeProp, BaseVariants } from '@core-types/components'

export type BaseUiBadgeProps<Colors extends string, Variants extends string, Sizes extends string> = {
    contentClass?: string

    color?: Colors
    variant?: Variants
    size?: Sizes

    customBackgroundColor?: string
    customTextColor?: string

    dense?: boolean
    square?: boolean
    pill?: boolean

    loading?: boolean
    disabled?: boolean
}

type BaseUiBadgeSlots<Colors extends string, Variants extends string, Sizes extends string> = {
    default: {}
    leading: {}
    trailing: {}
}

type ComponentOptions = {}

export function defineComponentBaseUiBadge<
    Colors extends string,
    Variants extends string = BaseVariants,
    Sizes extends string = SizeProp,
>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiBadgeProps<Colors, Variants, Sizes>, BaseUiBadgeSlots<Colors, Variants, Sizes>>) {
    return defineComponent(
        (props: BaseUiBadgeProps<Colors, Variants, Sizes>, ctx) => {

            const isDisabled = computed(() => props.disabled || props.loading)

            return () => (
                <span class={['sim-badge', {
                    [`sim-badge--c-${props.color}`]: props.color,
                    [`sim-badge--s-${props.size}`]: props.size,
                    [`sim-badge--v-${props.variant}`]: props.variant,
                    'sim-badge--pill': props.pill,
                    'sim-badge--dense': props.dense,
                    'sim-badge--square': props.square,
                    'sim-badge--loading': props.loading,
                    'sim-badge--disabled': isDisabled.value,
                }]}
                style={{ backgroundColor: props.customBackgroundColor, color: props.customTextColor }}
                >
                    <span class={['sim-badge__content', {
                        [`${props.contentClass}`]: props.contentClass,
                    }]}
                    >
                        {
                            // LEADING SLOT
                            (ctx.slots.leading !== undefined || options?.slots?.leading) && (
                                <span class="sim-badge__leading" aria-hidden="true">
                                    {renderSlot(ctx.slots.leading, options?.slots?.leading, {})}
                                </span>
                            )
                        }

                        {
                            // DEFAULT SLOT
                            renderSlot(ctx.slots.default, options?.slots?.default, {})
                        }

                        {
                            // TRAILING SLOT
                            (ctx.slots.trailing !== undefined || options?.slots?.trailing) && (
                                <span class="sim-badge__trailing" aria-hidden="true">
                                    {renderSlot(ctx.slots.trailing, options?.slots?.trailing, {})}
                                </span>
                            )
                        }
                    </span>
                </span>
            )
        },
        {
            props: {
                contentClass: {
                    type: String as PropType<BaseUiBadgeProps<Colors, Variants, Sizes>['contentClass']>,
                    default: options?.props?.contentClass?.default,
                    required: options?.props?.contentClass?.required ?? false,
                },

                color: {
                    // @ts-ignore
                    type: String as PropType<BaseUiBadgeProps<Colors, Variants, Sizes>['color']>,
                    default: options?.props?.color?.default,
                    required: options?.props?.color?.required ?? false,
                },
                variant: {
                    // @ts-ignore
                    type: String as PropType<BaseUiBadgeProps<Colors, Variants, Sizes>['variant']>,
                    default: options?.props?.variant?.default,
                    required: options?.props?.variant?.required ?? false,
                },
                size: {
                    // @ts-ignore
                    type: String as PropType<BaseUiBadgeProps<Colors, Variants, Sizes>['size']>,
                    default: options?.props?.size?.default,
                    required: options?.props?.size?.required ?? false,
                },

                customBackgroundColor: {
                    type: String as PropType<BaseUiBadgeProps<Colors, Variants, Sizes>['customBackgroundColor']>,
                    default: options?.props?.customBackgroundColor?.default,
                    required: options?.props?.customBackgroundColor?.required ?? false,
                },
                customTextColor: {
                    type: String as PropType<BaseUiBadgeProps<Colors, Variants, Sizes>['customTextColor']>,
                    default: options?.props?.customTextColor?.default,
                    required: options?.props?.customTextColor?.required ?? false,
                },

                dense: {
                    type: Boolean as PropType<BaseUiBadgeProps<Colors, Variants, Sizes>['dense']>,
                    default: options?.props?.dense?.default ?? false,
                    required: options?.props?.dense?.required ?? false,
                },
                square: {
                    type: Boolean as PropType<BaseUiBadgeProps<Colors, Variants, Sizes>['square']>,
                    default: options?.props?.square?.default ?? false,
                    required: options?.props?.square?.required ?? false,
                },
                pill: {
                    type: Boolean as PropType<BaseUiBadgeProps<Colors, Variants, Sizes>['pill']>,
                    default: options?.props?.pill?.default ?? false,
                    required: options?.props?.pill?.required ?? false,
                },

                loading: {
                    type: Boolean as PropType<BaseUiBadgeProps<Colors, Variants, Sizes>['loading']>,
                    default: options?.props?.loading?.default ?? false,
                    required: options?.props?.loading?.required ?? false,
                },
                disabled: {
                    type: Boolean as PropType<BaseUiBadgeProps<Colors, Variants, Sizes>['disabled']>,
                    default: options?.props?.disabled?.default ?? false,
                    required: options?.props?.disabled?.required ?? false,
                },

            },
            slots: Object as SlotsType<BaseUiBadgeSlots<Colors, Variants, Sizes>>,
            emits: {},
        }
    )
}

export default defineComponentBaseUiBadge()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiBadge.scss" as *;

</style>
