<template>
    <BaseUiButton
        :to="buttonIsLink ? productUrl : undefined"
        :on-click="buttonIsLink ? undefined : handleClick"
        :color="buttonColor"
        :disabled="disabled || !!(type === 'detail' && product?.isAddToCartButtonDisabled(variation) && product?.canBePurchased(variation))"
        class="w-full"
        :size="type === 'detail' ? 'large' : 'medium'"
    >
        {{ buttonLabel }}
    </BaseUiButton>
</template>

<script lang="ts" setup>
import { LazyModalProductWatchDog } from '#components'

const {
    product,
    amount = 1,
    variation,
    type = 'card',
    disabled = false,
} = defineProps<{
    product: InstanceType<typeof ProductModel> | null
    amount?: number
    variation?: InstanceType<typeof ProductVariationModel> | null
    type?: 'card' | 'detail',
    disabled?: boolean
}>()

const productUrl = computed<string>(() => getProductUrl(product, variation) ?? '')

const { t } = useI18n()

const buttonColor = computed(() => {
    if (type !== 'detail') {
        // sold out / unavailable
        if (!product?.canBePurchased(variation)) {
            return 'gray'
        }
    }

    // available
    return 'primary'
})

const buttonLabel = computed(() => {
    // sold out / unavailable
    if (!product?.canBePurchased(variation)) {
        if (type === 'detail') {
            return t('product.watch_availability_short')
        } else {
            return t('product.watch_availability')
        }
    }

    // available, but variations
    if (product?.hasVariations && !variation) {
        if (type === 'detail') {
            return t('product.select_variant')
        } else {
            return t('product.product_detail')
        }
    }

    // available, variation selected
    return t('product.add_to_cart')
})

const buttonIsLink = computed(() => {
    if (type !== 'detail') {
        // available, but variations
        if (product?.hasVariations && !variation) {
            return true
        }
    }

    // available, variation selected
    return false
})

const { addToCart } = useCart()
const { openModal } = useModals()

async function handleClick() {
    if (!product?.id) return

    if (product?.canBePurchased(variation)) {
        await addToCart({
            product_id: product.id,
            amount: amount,
            variation_id: variation?.id,
        })
    } else {
        await openModal(LazyModalProductWatchDog, {
            product: product,
            variation: variation,
        })
    }
}

</script>
