// FAVU - Fakulta výtvarných umění | FFA - Faculty of Fine Arts
// FAST -Fakulta stavební | FCE - Faculty of Civil Engineering
// FSI - Fakulta strojního inženýrství | FME - Faculty of Mechanical Engineering
// FIT - Fakulta informačních technologií | FIT - Faculty of Information Technology
// FA - Fakulta architektury | FA - Faculty of Architecture
// FCH - Fakulta chemická | FCH - Faculty of Chemistry
// FP - Fakulta podnikatelská | FBM - Faculty of Business and Management
// FEKT - Fakulta elektrotechniky a komunikačních technologií | FEEC - Faculty of Electrical Engineering and Communication
// CESA - Centrum sportovních aktivit | CESA - Centre of Sports Activities
// CVP - Centrum vzdělávání a poradenství | CECC - Continuing Education and Counselling Centre
// USI - Ústav soudního inženýrství | IFE - Institute of Forensic Engineering
// CEITEC VUT - Středoevropský technologický institut | CEITEC - Central European Institute of Technology
// CIS - Centrum informačních služeb | CIS - Center of Information Services
// UK - Ústřední knihovna | CL - Central Library
// VUTIUM - Nakladatelství VUTIUM | VUTIUM - VUTIUM Press
// CSC VUT - České polovodičové centrum | CSC BUT - Czech Semiconductor Centre
// KAM - Koleje a menzy | ACS - Accommodation and Catering Services
// RE - Rektorát | RE - Rectorate
// VUT - Vysoké učení technické v Brně | BUT - Brno University of Technology


const FacultyColor = {
    FAVU: '#E782A9',
    FAST: '#658D1B',
    FSI: '#004F71',
    FIT: '#00A9E0',
    FA: '#7A99AC',
    FCH: '#00AB8E',
    FP: '#8246AF',
    FEKT: '#003DA5',
    CESA: '#009CAB',
    CVP: '#898D8D',
    USI: '#201547',
    CEITEC: '#76c045',
    CIS: '#898D8D',
    UK: '#898D8D',
    VUTIUM: '#898D8D',
    CSC: '#898D8D',
    KAM: '#898D8D',
    RE: '#898D8D',
    VUT: '#E4002B',
}

export const getFacultyColor = (facultyName: string | null): string | undefined => {
    if (!facultyName) return undefined

    const normalizedName = facultyName.trim().toUpperCase()

    switch (normalizedName) {
        case 'FAVU': // CS
        case 'FFA':  // EN
            return FacultyColor.FAVU
        case 'FAST': // CS
        case 'FCE':  // EN
            return FacultyColor.FAST
        case 'FSI': // CS
        case 'FME': // EN
            return FacultyColor.FSI
        case 'FIT':
            return FacultyColor.FIT
        case 'FA':
            return FacultyColor.FA
        case 'FCH':
            return FacultyColor.FCH
        case 'FP':  // CS
        case 'FBM': // EN
            return FacultyColor.FP
        case 'FEKT': // CS
        case 'FEEC': // EN
            return FacultyColor.FEKT
        case 'CESA':
            return FacultyColor.CESA
        case 'CVP':  // CS
        case 'CECC': // EN
            return FacultyColor.CVP
        case 'USI': // CS
        case 'IFE': // EN
            return FacultyColor.USI
        case 'CEITEC VUT': // CS
        case 'CEITEC':     // EN
            return FacultyColor.CEITEC
        case 'CIS':
            return FacultyColor.CIS
        case 'UK': // CS
        case 'CL': // EN
            return FacultyColor.UK
        case 'VUTIUM':
            return FacultyColor.VUTIUM
        case 'CSC':     // CS
        case 'CSC BUT': // EN
            return FacultyColor.CSC
        case 'KAM': // CS
        case 'ACS': // EN
            return FacultyColor.KAM
        case 'RE':
            return FacultyColor.RE
        case 'VUT': // CS
        case 'BUT': // EN
            return FacultyColor.VUT
        default:
            return ''
    }
}
