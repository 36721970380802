<template>
    <BaseUiButton
        class="vut-fav-btn"
        size="auto"
        color="none"
        :class="{
            'vut-fav-btn--wishlisted': isWishlisted,
            'vut-fav-btn--full': type === 'full',
        }"
        :on-click="handleClick"
        :aria-label="ariaLabel"
        :show-loading="false"
    >
        <template #leading>
            <IconHeart :size="14" class="vut-fav-btn__icon" :filled="isWishlisted" />
        </template>

        <div v-if="type === 'full'" class="hidden whitespace-nowrap md:flex">
            {{ isWishlisted ? $t('product.remove_from_favorites') : $t('product.add_to_favorites') }}
        </div>
    </BaseUiButton>
</template>

<script lang="ts" setup>
const {
    product,
    variation,
    type = 'compact',
} = defineProps<{
    product: InstanceType<typeof ProductModel> | null | undefined
    variation?: InstanceType<typeof ProductVariationModel> | null
    type?: 'compact' | 'full'
}>()

const { t } = useI18n()
const { notifyInfo } = useNotifications()
const { addToWishlist, removeFromWishlist } = useProductWishlist()
const { loggedIn } = useAuth()

const _isWishlisted = ref<boolean | undefined>(undefined)
const isWishlisted = computed<boolean>(() => _isWishlisted.value ?? product?.isWishlisted(variation) ?? false)
watch([() => product, () => variation], (val) => {
    // reset the internal state when the props change
    _isWishlisted.value = undefined
})

const ariaLabel = computed<string>(() => isWishlisted.value ? t('product.remove_from_favorites') : t('product.add_to_favorites'))

async function handleClick() {
    if (!product) return
    if (!loggedIn.value) {
        notifyInfo(t('user.log_in_to_add_to_favorites'))
        // TODO: open login modal?
        return
    }

    if (isWishlisted.value) {
        // Remove from wishlist
        _isWishlisted.value = false  // optimistic update
        _isWishlisted.value = !await removeFromWishlist(product, variation)
    } else {
        // Add to wishlist
        _isWishlisted.value = true  // optimistic update
        _isWishlisted.value = await addToWishlist(product, variation)
    }
}
</script>

<style lang="scss" scoped>
.vut-fav-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0.5rem;

    height: 1.625rem;
    width: 1.625rem;

    background: $vut-c-white;
    border-radius: 100% !important;
}

.vut-fav-btn--full {
    padding: 1rem;
    font-family: $vut-ff-primary;
    text-transform: unset;
}

.vut-fav-btn__icon {
    color: $vut-c-gray;

    transition: color $vut-trans-time $vut-timing;
}

.vut-fav-btn:hover .vut-fav-btn__icon {
    color: $vut-c-primary;
}

.vut-fav-btn--wishlisted .vut-fav-btn__icon {
    color: $vut-c-primary;
}

.vut-fav-btn--wishlisted:hover .vut-fav-btn__icon {
    color: $vut-c-primary-darker;
}
</style>
